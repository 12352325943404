import gql from 'graphql-tag'

export const DRAFT_REQUEST_PAYMENT_MUTATION = gql`
  mutation RequestDraftPayment($input: DraftRequestPaymentInput!) {
    requestDraftPayment(input: $input) {
      clientMutationId
    }
  }
`

export const IDENTIFICATION_RISK_DETAILS_MUTATION = gql`
  mutation UpdateIdentificationRiskDetailsForBankPortal($input: UpdateBankIdentificationRiskDetailsInput!) {
    updateIdentificationRiskDetailsForBankPortal(input: $input) {
      clientMutationId
    }
  }
`

export const DRAFT_REJECT_APPLICATION_MUTATION = gql`
  mutation RejectDraft($input: DraftRejectInput!) {
    rejectDraft(input: $input) {
      clientMutationId
    }
  }
`
export const CREATE_CHANNEL_PARTNER_MUTATION = gql`
  mutation AddChannelPartnerForBankPortal($input: MutateChannelPartnerInput!) {
    addChannelPartnerForBankPortal(input: $input) {
      clientMutationId
    }
  }
`

export const UPDATE_CHANNEL_PARTNER_MUTATION = gql`
  mutation UpdateChannelPartnerForBankPortal($id: ID!, $input: MutateChannelPartnerInput!) {
    updateChannelPartnerForBankPortal(id: $id, input: $input) {
      clientMutationId
    }
  }
`

export const UPDATE_IDENTIFICATION_MUTATION = gql`
  mutation UpdateIdentificationForBankPortal($input: UpdateBankIdentificationInput!) {
    updateIdentificationForBankPortal(input: $input) {
      clientMutationId
      applicableRequirements {
        field
        message
        requirementMet
      }
    }
  }
`

export const UPDATE_ORGANISATION_MUTATION = gql`
  mutation UpdateOrganisationBankPortal($input: UpdateOrganisationInput!) {
    updateOrganisationBankPortal(input: $input) {
      clientMutationId
    }
  }
`

export const DRAFT_ESTABLISH_MUTATION = gql`
  mutation EstablishDraft($input: DraftEstablishInput!) {
    establishDraft(input: $input) {
      clientMutationId
      draft {
        status
      }
    }
  }
`

export const DOCUMENT_UPDATE_MUTATION = gql`
  mutation UpdateBankDocument($input: BankDocumentUpdateInput!) {
    updateBankDocument(input: $input) {
      clientMutationId
    }
  }
`

export const FACILITY_APPLICATION_UPDATE_STATUS_MUTATION = gql`
  mutation UpdateFacilityApplicationStatus($input: ChangeFacilityApplicationStatusInput!) {
    changeFacilityApplicationStatus(input: $input) {
      clientMutationId
    }
  }
`

export const CREATE_DRAFT_ATTACHMENT = gql`
  mutation CreateDraftAttachmentForBankPortal($input: CreateDraftAttachmentForBankPortalInput!) {
    createDraftAttachmentForBankPortal(input: $input) {
      clientMutationId
      draftAttachment {
        id
        contentType
        fileName
        received
        uploadPresignedUrl
        draftRequestedDocumentId
      }
    }
  }
`

export const REMOVE_DRAFT_ATTACHMENT = gql`
  mutation removeDraftAttachmentForBankPortal($input: RemoveDraftAttachmentForBankPortalInput!) {
    removeDraftAttachmentForBankPortal(input: $input) {
      clientMutationId
    }
  }
`

export const ESTABLISH_SURETY_BOND_DRAFT = gql`
  mutation EstablishSuretyBondDraft($input: DraftEstablishSuretyBondInput!) {
    establishSuretyBondDraft(input: $input) {
      clientMutationId
    }
  }
`

export const REQUEST_DRAFT_CHANGES = gql`
  mutation RequestDraftChanges($input: DraftRequestChangeInput!) {
    requestDraftChanges(input: $input) {
      clientMutationId
    }
  }
`
export const REMOVE_MEMBER_FROM_ORGANISATION = gql`
  mutation RemoveMemberForBankPortal($input: RemoveMemberInput!) {
    removeMemberForBankPortal(input: $input) {
      clientMutationId
    }
  }
`
export const RESYNC_COGNITO_BANK_USERS = gql`
  mutation CognitoReSyncBankUser($clientMutationId: String!) {
    cognitoResyncBankUser(clientMutationId: $clientMutationId) {
      clientMutationId
    }
  }
`
export const ADD_UPDATE_SUPPLIER = gql`
  mutation AddUpdateSupplier($input: CreateOrUpdateSupplierInput!) {
    createOrUpdateSupplier(input: $input) {
      clientMutationId
    }
  }
`

export const REMOVE_SUPPLIER = gql`
  mutation RemoveSupplier($input: ID!, $clientMutationId: String!) {
    removeSupplier(input: $input, clientMutationId: $clientMutationId) {
      clientMutationId
    }
  }
`

export const ADD_UPDATE_FEE_SUMMARY = gql`
  mutation CreateOrUpdateFeeSummary($input: CreateOrUpdateFeeSummaryInput!) {
    createOrUpdateFeeSummary(input: $input) {
      clientMutationId
      feeSummary {
        createdAt
        establishmentFee
        id
        feeSummaryStatus
        name
        recurringFeePercentage
        supplierId
      }
    }
  }
`

export const DELETE_ADMIN_FROM_COGNITO = gql`
  mutation CognitoDeleteBankUser($clientMutationId: String!, $userName: ID!) {
    cognitoDeleteBankUser(clientMutationId: $clientMutationId, userName: $userName) {
      clientMutationId
      user {
        userName
        emailAddress
        firstName
        lastName
        lastUpdatedAt
        phoneNumber
      }
    }
  }
`
export const ADD_ADMIN_TO_COGNITO = gql`
  mutation CognitoAddBankUser($user: CognitoUserInput!) {
    cognitoAddBankUser(user: $user) {
      clientMutationId
      user {
        userName
        emailAddress
        firstName
        lastName
        lastUpdatedAt
        phoneNumber
        isSmsMfaRequired
      }
    }
  }
`

export const UPDATE_ADMIN_IN_COGNITO = gql`
  mutation CognitoUpdateBankUser($user: CognitoUserInput!) {
    cognitoUpdateBankUser(user: $user) {
      clientMutationId
      user {
        userName
        emailAddress
        firstName
        lastName
        lastUpdatedAt
        phoneNumber
        isSmsMfaRequired
      }
    }
  }
`

export const CONVERT_TO_ASSURO_FACILITY = gql`
  mutation ConvertDocumentFacilityForBankPortal($input: ConvertDocumentFacilityForBankPortalInput!) {
    convertDocumentFacilityForBankPortal(input: $input) {
      clientMutationId
      documentFacility {
        id
        isAssuroFacility
      }
    }
  }
`

export const DRAFT_UPDATE_MUTATION = gql`
  mutation UpdateDraftForBankPortal($input: UpdateBankDraftInput!) {
    updateDraftForBankPortal(input: $input) {
      clientMutationId
      applicableRequirements {
        field
        message
        requirementMet
      }
    }
  }
`

export const UPDATE_IDENTIFICATION_INDIVIDUAL_GREEN_ID = gql`
  mutation updateGreenIdForBankPortal($input: UpdateBankGreenIdInput) {
    updateGreenIdForBankPortal(input: $input) {
      clientMutationId
      identificationIndividual {
        greenIdVerificationStatus
      }
    }
  }
`

export const CREATE_SIMPLE_KYC_DATA_BY_IDENTIFICATION_ID = gql`
  mutation createSimpleKycDataByIdentificationIdForBankPortal($input: CreateSimpleKycDataInput!) {
    createSimpleKycDataByIdentificationIdForBankPortal(input: $input) {
      clientMutationId
    }
  }
`

export const UPDATE_SIMPLE_KYC_DATA = gql`
  mutation updateSimpleKycDataFromApi($input: UpdateSimpleKycDataInput!) {
    updateSimpleKycDataFromApi(input: $input) {
      clientMutationId
    }
  }
`

export const GENERATE_NEW_STRIPE_SUBSCRIPTION_MUTATION_BANK_PORTAL = gql`
  mutation GenerateNewStripeSubscriptionBankPortal($input: BankPortalGenerateNewStripeSubscriptionInput!) {
    generateNewStripeSubscriptionForBankPortal(input: $input) {
      clientMutationId
    }
  }
`

export const IMPORT_EXISTING_SIMPLE_KYC_DATA = gql`
  mutation ImportAllExistingSimpleKycApplications($input: ImportExistingSimpleKycDataInput!) {
    importAllExistingSimpleKycApplications(input: $input) {
      clientMutationId
    }
  }
`
export const RESEND_ADDITIONAL_INVOICES = gql`
  mutation ResendInvoices($input: String, $referenceNumber: String, $clientMutationId: String) {
    resendInvoices(input: $input, referenceNumber: $referenceNumber, clientMutationId: $clientMutationId) {
      clientMutationId
      allRecipients
    }
  }
`

export const DOCUMENT_REESTABLISH_MUTATION = gql`
  mutation ReestablishBankDocument($documentId: ID!, $clientMutationId: ID!) {
    reestablishBankDocument(documentId: $documentId, clientMutationId: $clientMutationId) {
      clientMutationId
    }
  }
`

export const REACTIVATE_SUBSCRIPTION = gql`
  mutation reactivateSubscription(
    $subscriptionId: String!
    $clientMutationId: String!
    $backdateSubscriptionTo: DateTime!
  ) {
    reactivateSubscription(
      subscriptionId: $subscriptionId
      clientMutationId: $clientMutationId
      backdateSubscriptionTo: $backdateSubscriptionTo
    )
  }
`

export const CREATE_FINANCE_RECORD = gql`
  mutation createFinanceRecordForBankPortal($input: FinanceRecordInput!) {
    createFinanceRecordForBankPortal(input: $input) {
      amount
      dateTime
      financeType
      financier
      id
      organisationId
      performedBy
    }
  }
`

export const EDIT_FINANCE_RECORD = gql`
  mutation editFinanceRecordForBankPortal($input: EditFinanceRecordInput!) {
    editFinanceRecordForBankPortal(input: $input) {
      amount
      dateTime
      financeType
      financier
      id
      organisationId
      performedBy
    }
  }
`

export const DELETE_FINANCE_RECORD = gql`
  mutation deleteFinanceRecordFromBankPortal($id: ID!) {
    deleteFinanceRecordFromBankPortal(id: $id) {
      id
      amount
      dateTime
      organisationId
    }
  }
`

export const SET_DRAFT_AVAILABLE_TO_SUPPLIER = gql`
  mutation setDraftAvailableToSupplier($draftId: ID!, $clientMutationId: String!) {
    setDraftAvailableToSupplier(draftId: $draftId, clientMutationId: $clientMutationId)
  }
`

export const SET_BG_APPLICATION_CONTACT_DETAILS = gql`
  mutation SetApplicationContactDetails($input: ApplicationContactDetailsInput!) {
    setApplicationContactDetailsForBankPortal(input: $input) {
      draftId
    }
  }
`
export const UPDATE_DRAFT_BG_SWITCH = gql`
  mutation UpdateDraftBGSwitchForBankPortal($input: UpdateDraftBGSwitchInput!) {
    updateDraftBGSwitchForBankPortal(input: $input) {
      draftBGSwitch {
        id
      }
    }
  }
`

export const GENERATE_DOCUMENT_REPORT = gql`
  mutation generateDocumentReport($input: DocumentReportInput!) {
    generateDocumentReport(input: $input) {
      id
      displayName
    }
  }
`

export const CREATE_REQUIRED_DOCUMENT = gql`
  mutation createRequiredDocumentForBankPortal($input: CreateRequiredDocumentForBankPortalInput!) {
    createRequiredDocumentForBankPortal(input: $input) {
      id
    }
  }
`

export const CREATE_REQUIRED_DOCUMENT_MESSAGE = gql`
  mutation createRequiredDocumentMessageForBankPortal($input: CreateRequiredDocumentMessageInput!) {
    createRequiredDocumentMessageForBankPortal(input: $input) {
      id
    }
  }
`

export const ACTION_REQUIRED_DOCUMENT_MESSAGE = gql`
  mutation actionRequiredDocumentMessageForBankPortal($input: ActionRequiredDocumentMessageInput!) {
    actionRequiredDocumentMessageForBankPortal(input: $input) {
      id
    }
  }
`

export const UPDATE_COGNITO_ADMIN_USER = gql`
  mutation CognitoUpdateAuthenticatedBankUser($request: AuthenticatedCognitoUserModificationRequestInput!) {
    cognitoUpdateAuthenticatedBankUser(request: $request) {
      clientMutationId
    }
  }
`
export const REFERRAL_PORTAL_SET_ORGANISATION_ASSOCIATION = gql`
  mutation ReferralPortalSetOrganisationAssociation($input: SetOrganisationAssociationInput!) {
    referralPortalSetOrganisationAssociation(input: $input) {
      id
    }
  }
`
export const REFERRAL_PORTAL_ADD_PARTNER = gql`
  mutation ReferralPortalCreateEntity($input: CreateEntityRequestInput!) {
    referralPortalCreateEntity(input: $input) {
      id
    }
  }
`

export const REFERRAL_PORTAL_UPDATE_PARTNER = gql`
  mutation referralPortalUpdateEntity($input: UpdateEntityRequestInput!) {
    referralPortalUpdateEntity(input: $input) {
      id
    }
  }
`

export const REFERRAL_PORTAL_ADD_MEMBER = gql`
  mutation ReferralPortalCreateMember($input: ReferralPortalMemberCreateInput!) {
    referralPortalCreateMember(input: $input) {
      id
    }
  }
`

export const RESEND_REFERRAL_PORTAL_MEMBER_INVITATION = gql`
  mutation ReferralPortalResendInvitation($input: ResendInvitationRequestInput!) {
    referralPortalResendInvitation(input: $input)
  }
`

export const DELETE_REFERRAL_PORTAL_MEMBER_ = gql`
  mutation ReferralPortalDeleteMember($referralPortalMemberId: ID!) {
    referralPortalDeleteMember(referralPortalMemberId: $referralPortalMemberId) {
      id
      status
    }
  }
`

export const REFERRAL_PORTAL_UPDATE_MEMBER = gql`
  mutation ReferralPortalUpdateMember($input: ReferralPortalMemberUpdateInput!) {
    referralPortalUpdateMember(input: $input) {
      id
    }
  }
`
