import { RouteConfig } from 'vue-router'
import { BankUserRole } from '~/types'

const Drafts = () => import('@/views/Drafts.vue')
const DraftView = () => import('@/views/DraftView.vue')
const BgApplication = () => import('@/views/BgApplication.vue')
const SupplierBGApplicationView = () => import('@/views/SupplierBGApplicationView.vue')
const DraftWordingView = () => import('@/views/DraftWordingView.vue')
const DocumentView = () => import('@/views/DocumentView.vue')
const FacilityApplicationView = () => import('@/views/FacilityApplicationView.vue')
const FacilityApplications = () => import('@/views/FacilityApplications.vue')
const ImportJobs = () => import('@/views/ImportJobs.vue')
const ImportJobView = () => import('@/views/ImportJobView.vue')
const Users = () => import('@/views/Users.vue')
const UserView = () => import('@/views/UserView.vue')
const ChannelPartners = () => import('@/views/ChannelPartners.vue')
const ChannelPartnerView = () => import('@/views/ChannelPartnerView.vue')
const Organisations = () => import('@/views/Organisations.vue')
const OrganisationView = () => import('@/views/OrganisationView.vue')
const IdentificationView = () => import('@/views/IdentificationView.vue')
const GreenIdIdentification = () => import('@/views/GreenIdIdentificationView.vue')
const Payments = () => import('@/views/Payments.vue')
const Identifications = () => import('@/views/Identifications.vue')
const Homepage = () => import('@/views/Homepage.vue')
const Facilities = () => import('@/views/Facilities.vue')
const FacilityView = () => import('@/views/FacilityView.vue')
const OriginationView = () => import('@/views/OriginationView.vue')
const NotificationsView = () => import('@/views/NotificationsView.vue')
const PageNotFound = () => import('~/components/PageNotFound.vue')
const Suppliers = () => import('@/views/Suppliers.vue')
const SupplierView = () => import('@/views/SupplierView.vue')
const Reports = () => import('@/views/Reports.vue')
//Referral Partner Pages
const ReferralDeals = () => import('@/views/ReferralDeals.vue')
const UserProfile = () => import('@/views/UserProfile.vue')
const CorporatePartnersView = () => import('@/views/CorporatePartnersView.vue')
const ReferralPartnersView = () => import('@/views/ReferralPartnersView.vue')
const ReferralMembersView = () => import('@/views/ReferralMembersView.vue')

//Account pages
const Account = () => import('@/views/Account.vue')
const AccountLoginCard = () => import('@/components/AccountLoginCard.vue')
const AccountResetPasswordCard = () => import('@/components/AccountResetPasswordCard.vue')
const AccountEnterOtpCard = () => import('@/components/AccountEnterOtpCard.vue')
const AccountResetCard = () => import('~/components/AccountResetCard.vue')

//Agency Agreement
const AgencyAgreement = () => import('@/components/BankGuaranteeAcceptAgencyAgreement.vue')

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'default',
    component: Homepage,
    meta: {
      authorize: [
        BankUserRole.ASSURO_GENERAL,
        BankUserRole.ASSURO_RELATIONSHIP_MANAGER,
        BankUserRole.EXTERNAL_SUPPLIER,
        BankUserRole.EXTERNAL_REFERRALPORTAL_ADMIN,
        BankUserRole.EXTERNAL_REFERRALPORTAL_REFERRER
      ]
    }
  },
  {
    path: '/account',
    name: 'account',
    component: Account,
    meta: { anonymous: true },
    redirect: { name: 'account-login' },
    children: [
      {
        path: 'login',
        name: 'account-login',
        component: AccountLoginCard,
        meta: { anonymous: true }
      },
      {
        path: 'reset',
        name: 'account-reset',
        component: AccountResetCard,
        meta: { anonymous: true }
      },
      {
        path: 'reset-password',
        name: 'reset-password',
        component: AccountResetPasswordCard,
        meta: { anonymous: true }
      },
      {
        path: 'otp',
        name: 'otp',
        component: AccountEnterOtpCard,
        meta: { anonymous: true }
      },
      {
        path: '/complete',
        redirect: { name: 'default' }
      }
    ]
  },
  {
    path: '/drafts',
    name: 'drafts',
    component: Drafts,
    meta: {
      authorize: [BankUserRole.ASSURO_GENERAL, BankUserRole.ASSURO_RELATIONSHIP_MANAGER, BankUserRole.EXTERNAL_SUPPLIER]
    }
  },
  {
    path: '/drafts/:draftId/view',
    name: 'draft-view',
    component: DraftView,
    meta: { authorize: [BankUserRole.ASSURO_GENERAL, BankUserRole.ASSURO_RELATIONSHIP_MANAGER] },

    props: true
  },
  {
    path: '/drafts/:draftId/bgApplication',
    name: 'bg-application',
    component: BgApplication,
    meta: { authorize: [BankUserRole.ASSURO_GENERAL, BankUserRole.ASSURO_RELATIONSHIP_MANAGER] },

    props: true
  },
  {
    path: '/drafts/:draftId/supplier',
    name: 'supplier-draft-view',
    component: SupplierBGApplicationView,
    meta: {
      authorize: [BankUserRole.ASSURO_GENERAL, BankUserRole.ASSURO_RELATIONSHIP_MANAGER, BankUserRole.EXTERNAL_SUPPLIER]
    },

    props: true
  },
  {
    path: '/drafts/:draftId/agency-agreement',
    name: 'agency-agreement',
    component: AgencyAgreement,
    meta: {
      authorize: [BankUserRole.ASSURO_GENERAL, BankUserRole.ASSURO_RELATIONSHIP_MANAGER, BankUserRole.EXTERNAL_SUPPLIER]
    },

    props: true
  },
  {
    path: '/drafts/:draftId/wording/:draftRequestedSuretyId',
    name: 'draft-wording',
    component: DraftWordingView,
    meta: { authorize: [BankUserRole.ASSURO_GENERAL, BankUserRole.ASSURO_RELATIONSHIP_MANAGER] },
    props: route => ({ draftId: route.params.draftId, draftRequestedSuretyId: route.params.draftRequestedSuretyId })
  },
  {
    path: '/documents/:documentId/view',
    name: 'document-view',
    component: DocumentView,
    meta: { authorize: [BankUserRole.ASSURO_GENERAL, BankUserRole.ASSURO_RELATIONSHIP_MANAGER] },

    props: true
  },
  {
    path: '/facilityApplications',
    name: 'facility-list',
    component: FacilityApplications,
    meta: { authorize: [BankUserRole.ASSURO_GENERAL, BankUserRole.ASSURO_RELATIONSHIP_MANAGER] },
    props: true
  },
  {
    path: '/facilityApplications/:facilityApplicationId/view',
    name: 'facility-view',
    component: FacilityApplicationView,
    meta: { authorize: [BankUserRole.ASSURO_GENERAL, BankUserRole.ASSURO_RELATIONSHIP_MANAGER] },
    props: true
  },

  {
    path: '/import-jobs',
    name: 'import-jobs',
    component: ImportJobs,
    meta: { authorize: [BankUserRole.ASSURO_GENERAL, BankUserRole.ASSURO_RELATIONSHIP_MANAGER] }
  },
  {
    path: '/import-jobs/:importJobId/view',
    name: 'import-job-view',
    component: ImportJobView,
    meta: { authorize: [BankUserRole.ASSURO_GENERAL, BankUserRole.ASSURO_RELATIONSHIP_MANAGER] },
    props: true
  },
  {
    path: '/suppliers',
    name: 'supplier-list',
    component: Suppliers,
    meta: { authorize: [BankUserRole.ASSURO_GENERAL, BankUserRole.ASSURO_RELATIONSHIP_MANAGER] },
    props: true
  },
  {
    path: '/suppliers/:id',
    name: 'supplier-view',
    component: SupplierView,
    meta: { authorize: [BankUserRole.ASSURO_GENERAL, BankUserRole.ASSURO_RELATIONSHIP_MANAGER] },
    props: true
  },
  {
    path: '/reports',
    name: 'reports-view',
    component: Reports,
    meta: {
      authorize: [BankUserRole.ASSURO_GENERAL, BankUserRole.ASSURO_RELATIONSHIP_MANAGER, BankUserRole.EXTERNAL_SUPPLIER]
    },
    props: true
  },
  {
    path: '/deals',
    name: 'deals-view',
    component: ReferralDeals,
    meta: {
      authorize: [
        BankUserRole.ASSURO_GENERAL,
        BankUserRole.ASSURO_RELATIONSHIP_MANAGER,
        BankUserRole.EXTERNAL_REFERRALPORTAL_ADMIN,
        BankUserRole.EXTERNAL_REFERRALPORTAL_REFERRER
      ]
    },
    props: true
  },
  {
    path: '/myProfile',
    name: 'my-profile-view',
    component: UserProfile,
    props: true,
    meta: {
      authorize: [
        BankUserRole.ASSURO_GENERAL,
        BankUserRole.ASSURO_RELATIONSHIP_MANAGER,
        BankUserRole.EXTERNAL_REFERRALPORTAL_ADMIN,
        BankUserRole.EXTERNAL_REFERRALPORTAL_REFERRER,
        BankUserRole.EXTERNAL_SUPPLIER
      ]
    }
  },
  {
    path: '/users',
    name: 'user-list',
    component: Users,
    meta: { authorize: [BankUserRole.ASSURO_GENERAL, BankUserRole.ASSURO_RELATIONSHIP_MANAGER] },
    props: true
  },
  {
    path: '/users/:userId',
    name: 'user-view',
    component: UserView,
    meta: { authorize: [BankUserRole.ASSURO_GENERAL, BankUserRole.ASSURO_RELATIONSHIP_MANAGER] },
    props: true
  },
  {
    path: '/channel-partners',
    name: 'channel-partner-list',
    component: ChannelPartners,
    meta: { authorize: [BankUserRole.ASSURO_GENERAL, BankUserRole.ASSURO_RELATIONSHIP_MANAGER] },
    props: true
  },
  {
    path: '/channel-partners/:channelPartnerId',
    name: 'channel-partner-view',
    component: ChannelPartnerView,
    meta: { authorize: [BankUserRole.ASSURO_GENERAL, BankUserRole.ASSURO_RELATIONSHIP_MANAGER] },
    props: true
  },
  {
    path: '/organisations',
    name: 'organisation-list',
    component: Organisations,
    meta: { authorize: [BankUserRole.ASSURO_GENERAL, BankUserRole.ASSURO_RELATIONSHIP_MANAGER] },
    props: true
  },
  {
    path: '/payments',
    name: 'payment-list',
    component: Payments,
    meta: { authorize: [BankUserRole.ASSURO_GENERAL, BankUserRole.ASSURO_RELATIONSHIP_MANAGER] },
    props: true
  },
  {
    path: '/organisations/:organisationId/:enableEditing?',
    name: 'organisation-view',
    component: OrganisationView,
    meta: { authorize: [BankUserRole.ASSURO_GENERAL, BankUserRole.ASSURO_RELATIONSHIP_MANAGER] },
    props: true
  },
  {
    path: '/identifications/:identificationId',
    name: 'identification-view',
    component: IdentificationView,
    meta: { authorize: [BankUserRole.ASSURO_GENERAL, BankUserRole.ASSURO_RELATIONSHIP_MANAGER] },
    props: true
  },
  {
    path: '/identifications/:identificationId/individual/:identificationIndividualId',
    name: 'identification-individual-view',
    component: GreenIdIdentification,
    meta: { authorize: [BankUserRole.ASSURO_GENERAL, BankUserRole.ASSURO_RELATIONSHIP_MANAGER] },
    props: true
  },
  {
    path: '/identifications',
    name: 'identification-list',
    component: Identifications,
    meta: { authorize: [BankUserRole.ASSURO_GENERAL, BankUserRole.ASSURO_RELATIONSHIP_MANAGER] },
    props: true
  },
  {
    path: '/facilities',
    name: 'facilities',
    component: Facilities,
    meta: { authorize: [BankUserRole.ASSURO_GENERAL, BankUserRole.ASSURO_RELATIONSHIP_MANAGER] },
    props: true
  },
  {
    path: '/facilities/:facilityId',
    name: 'facility',
    component: FacilityView,
    meta: { authorize: [BankUserRole.ASSURO_GENERAL, BankUserRole.ASSURO_RELATIONSHIP_MANAGER] },
    props: true
  },
  {
    path: '/originations/:originationId',
    name: 'origination-view',
    component: OriginationView,
    meta: { authorize: [BankUserRole.ASSURO_GENERAL, BankUserRole.ASSURO_RELATIONSHIP_MANAGER] },
    props: true
  },
  {
    path: '/notifications',
    name: 'notifications-view',
    component: NotificationsView,
    meta: { authorize: [BankUserRole.ASSURO_GENERAL, BankUserRole.ASSURO_RELATIONSHIP_MANAGER] },
    props: true
  },
  {
    path: '/corporatePartners',
    name: 'corporate-partners-view',
    component: CorporatePartnersView,
    meta: { authorize: [BankUserRole.ASSURO_GENERAL, BankUserRole.ASSURO_RELATIONSHIP_MANAGER] },
    props: true
  },
  {
    path: '/referralPartners',
    name: 'referral-partners-view',
    component: ReferralPartnersView,
    meta: {
      authorize: [
        BankUserRole.ASSURO_GENERAL,
        BankUserRole.ASSURO_RELATIONSHIP_MANAGER,
        BankUserRole.EXTERNAL_REFERRALPORTAL_ADMIN
      ]
    },
    props: true
  },
  {
    path: '/referralMembers',
    name: 'referral-members-view',
    component: ReferralMembersView,
    meta: {
      authorize: [
        BankUserRole.ASSURO_GENERAL,
        BankUserRole.ASSURO_RELATIONSHIP_MANAGER,
        BankUserRole.EXTERNAL_REFERRALPORTAL_ADMIN
      ]
    },
    props: true
  },
  { path: '*', name: 'page-not-found', component: PageNotFound }
]

export { routes }
